export const Sony = ({className}) => {
    return (
        <svg
            x="0px"
            y="0px"
            viewBox="0 0 70.1 19.2"
            preserveAspectRatio="xMidYMid meet"
            className={`brand-logo ${className}`}
        >
            <g>
                <path className="svg-fill" d="M24.9,2.9c-2.4,0-5,0.8-6.5,2.2c-1.2,1.1-1.8,2.5-1.8,4c0,1.5,0.6,2.9,1.8,4c1.6,1.4,3.9,2.2,6.5,2.2
		c2.5,0,4.9-0.7,6.5-2.2c1.1-1,1.7-2.6,1.7-4c0-1.5-0.6-2.9-1.7-4C29.8,3.6,27.6,2.9,24.9,2.9 M28.4,12.3c-0.9,0.9-2.1,1.3-3.5,1.3
		c-1.3,0-2.6-0.5-3.5-1.3c-0.8-0.8-1.3-2-1.3-3.2c0-1.2,0.4-2.4,1.3-3.2c0.9-0.8,2.1-1.3,3.5-1.3c1.3,0,2.6,0.5,3.5,1.3
		c0.8,0.8,1.3,2,1.3,3.2C29.7,10.4,29.3,11.4,28.4,12.3"/>
                <path className="svg-fill" d="M12.2,8.4c-0.4-0.2-0.9-0.4-1.4-0.5C9.9,7.7,7.9,7.4,7,7.3C6,7.2,4.2,7,3.6,6.8C3.3,6.8,2.9,6.6,2.9,6.1
		c0-0.4,0.2-0.7,0.6-0.9c0.6-0.4,1.9-0.6,3.1-0.6c1.5,0,2.8,0.3,3.6,0.7c0.3,0.1,0.6,0.3,0.9,0.5C11.5,6,11.9,6.5,12,7.2h1.4V3.5
		h-1.5v0.4c0,0.1-0.1,0.3-0.4,0.2c-0.7-0.4-2.6-1.1-5-1.1C5,2.9,3.5,3.2,2.2,3.8C1,4.3,0,5.2,0,6.6c0,1.3,0.8,2.1,0.8,2
		C1.2,9,1.7,9.5,3.2,9.9c0.7,0.1,2.1,0.4,3.5,0.5c1.4,0.2,2.8,0.3,3.3,0.4c0.4,0.1,1.2,0.3,1.2,1.1c0,0.8-0.8,1.1-0.9,1.1
		c-0.1,0.1-1.1,0.5-2.8,0.5c-1.3,0-2.8-0.4-3.3-0.6c-0.6-0.2-1.3-0.5-1.9-1.3c-0.2-0.2-0.4-0.7-0.4-1.2H0.3v4.3h1.7v-0.6
		c0-0.1,0.1-0.3,0.4-0.2c0.4,0.2,1.4,0.6,2.5,0.8c0.9,0.2,1.5,0.3,2.6,0.3c1.8,0,2.8-0.3,3.5-0.5c0.6-0.2,1.4-0.5,2.1-1
		c0.3-0.3,1.1-1,1.1-2.3c0-1.2-0.7-2-0.9-2.2C13,8.8,12.6,8.5,12.2,8.4"/>
                <path className="svg-fill" d="M45.5,5h1.4c0.7,0,0.8,0.3,0.8,0.9l0.1,4.6l-7.5-6.8h-5.4V5H36c0.9,0,0.9,0.5,0.9,0.9v6.4c0,0.4,0,0.9-0.5,0.9h-1.5v1.4
		h6.3v-1.4h-1.5c-0.6,0-0.6-0.3-0.7-0.9V6.8l8.7,7.7h2.2l-0.1-8.7c0-0.6,0-0.9,0.7-0.9h1.4V3.6h-6.4V5z"/>
                <path className="svg-fill" d="M63.6,13.2l-0.3-0.3l0-0.3v-2.2l0.1-0.2c0.1-0.1,4-4.6,4.2-4.8C67.9,5.1,68.1,5,68.6,5H70V3.6h-6.2V5h0.9
		c0.3,0,0.3,0.3,0.2,0.4l-2.6,3.1l-0.1,0l-2.6-3.1c-0.2-0.2,0-0.4,0.2-0.4h0.9V3.6h-7.5V5h1.3c0.6,0,1,0.4,1.2,0.6
		c0.2,0.2,4,4.5,4.1,4.7l0.1,0.2v2.2l0,0.3l-0.3,0.3l-0.4,0h-1.3v1.4h7.5v-1.4H64L63.6,13.2z"/>
                <path className="svg-fill" d="M69.3,13.9l0-0.3c0-0.3-0.1-0.3-0.2-0.3c0.1-0.1,0.3-0.2,0.3-0.4c0-0.4-0.3-0.5-0.6-0.5h-0.8v1.8h0.2v-0.8h0.6
		c0.3,0,0.3,0.2,0.3,0.3l0,0.5h0.3v0L69.3,13.9z M68.7,13.2h-0.6v-0.6h0.6c0.1,0,0.3,0,0.3,0.3C69.1,13.1,68.9,13.2,68.7,13.2"/>
                <path className="svg-fill" d="M68.6,11.8c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5C70.1,12.4,69.4,11.8,68.6,11.8 M68.6,14.6
		c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3C69.9,14,69.3,14.6,68.6,14.6"/>
            </g>
        </svg>
    );
}

export const TheSun = ({className}) => {
    return (
        <svg
            x="0px"
            y="0px"
            viewBox="0 0 51.1 19.2"
            preserveAspectRatio="xMidYMid meet"
            className={`brand-logo ${className}`}
        >
            <g>
                <path className="svg-fill" d="M13,1.8l0.3,0c0.2,0,0.4,0,0.5,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.4,0c0.6,0,1.1,0.2,1.7,0.3
		c0.3,0.1,0.7,0.2,1,0.4c0.4,0.2,0.8,0.4,1.1,0.6c0.7,0.5,1.3,1.3,1.5,2.2c0,0-0.1,0.1-0.1,0.1c-1.1,0.2-2.2,0.4-3.2,0.7
		c-0.3,0.1-0.6,0.1-1,0.2c0,0-0.1,0-0.2,0c-0.3,0.1-0.6,0.2-1,0.2c0,0-0.1,0-0.1-0.1C14.1,6.4,14,6.2,13.9,6
		c-0.1-0.1-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.2-0.7-0.3c-0.3-0.1-0.5-0.1-0.8-0.1l-0.2,0l-0.8,0c-0.5,0.1-1,0.1-1.4,0.3
		C9.2,5.7,9,6,9,6.3c0.1,0.6,0.7,0.6,1.2,0.7c0.7,0.1,1.4,0.2,2,0.4c0.2,0,0.5,0.1,0.7,0.1c0,0,0.1,0,0.2,0c0.4,0,0.7,0.1,1,0.2
		c0.1,0,0.2,0,0.2,0.1c0.2,0,0.4,0.1,0.5,0.1c0.4,0.1,0.8,0.2,1.1,0.3c0.9,0.3,1.8,0.9,2.3,1.7c0.2,0.4,0.3,0.8,0.3,1.2
		c0,0.3,0,0.5,0,0.8c-0.1,0.3-0.1,0.7-0.3,1c-0.6,1.3-1.7,2.3-3,2.9c-0.5,0.3-1.1,0.5-1.6,0.7c-0.3,0.1-0.7,0.2-1,0.3
		c-0.1,0.1-0.3,0.1-0.4,0.1c-0.8,0.2-1.7,0.3-2.5,0.4c-0.1,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0c-0.3,0-0.7,0-1,0c-0.5,0-1,0-1.5-0.1
		c-0.5,0-1-0.1-1.5-0.2c-0.2-0.1-0.4,0-0.6-0.1c-0.9-0.2-1.8-0.6-2.6-1.1c-0.2-0.1-0.3-0.2-0.5-0.4c-0.4-0.3-0.7-0.8-1-1.3
		C0.2,13.8,0,13.3,0,12.8c0-0.1,0.1,0,0.2-0.1c0.4,0,0.7-0.1,1.1-0.1c0.2-0.1,0.4,0,0.5-0.1c0.2,0,0.4,0,0.6-0.1l0.2,0
		c0.4,0,0.9-0.1,1.3-0.2c0.2,0,0.4,0,0.5-0.1c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.5-0.1c0.1,0,0.3,0,0.4,0c0,0.4,0.2,0.7,0.5,1
		c0.2,0.2,0.4,0.4,0.7,0.5c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0c0.3,0,0.7,0,1,0
		c0.4,0,0.8-0.1,1.1-0.2c0.4-0.1,0.7-0.3,0.9-0.7c0.1-0.2,0-0.4-0.1-0.6c-0.2-0.3-0.6-0.4-0.9-0.5c-0.7-0.2-1.5-0.3-2.2-0.4
		c-0.3,0-0.6-0.1-0.9-0.2c-0.3,0-0.6-0.1-1-0.2c-1.3-0.3-2.8-0.7-3.8-1.7C2.7,8.9,2.4,8.3,2.5,7.6c0-1.1,0.6-2.1,1.3-2.8
		C4.3,4.1,5,3.6,5.8,3.2c0.9-0.5,1.9-0.8,2.9-1C9,2.1,9.3,2.1,9.6,2c0.3-0.1,0.7-0.1,1-0.1c0.1-0.1,0.2,0,0.2,0c0.2,0,0.4,0,0.6,0
		c0.1,0,0.2-0.1,0.3,0C12.2,1.8,12.6,1.9,13,1.8L13,1.8z"/>
                <path className="svg-fill" d="M48.4,5.9c0.7,0.1,1.4,0.3,2,0.8c0.5,0.4,0.7,1,0.7,1.6c0,0.1,0,0.1,0,0.2c0,0.7-0.2,1.4-0.4,2
		c-0.3,1-1.8,6.7-1.8,6.7l-5.9,0c0,0,0.1-0.3,0.1-0.4c0.3-1.2,0.6-2.4,0.9-3.6c0.3-1.1,0.6-2.2,0.9-3.3c0.1-0.4,0.1-0.8-0.2-1
		c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2,0.3-1.6,0.8c-0.3,0.5-0.7,2-0.7,2L40,17.2l-5.9,0C34.2,16.6,37,6,37,6
		l5.5,0l-0.3,1.5l0,0c0.3-0.4,0.7-0.7,1.2-0.9C44.1,6.2,45,6,45.9,5.9c0.3,0,0.5-0.1,0.8-0.1c0.3,0,0.6,0,0.9,0
		C47.6,5.8,48.2,5.9,48.4,5.9L48.4,5.9z"/>
                <path className="svg-fill" d="M35.9,6l-2.9,11.1l-5.6,0c0,0,0.3-1.1,0.4-1.8h0c-0.6,0.7-1.4,1.2-2.2,1.5c-0.7,0.3-1.6,0.4-2.3,0.5
		c-0.1,0-1.2,0-1.2,0c-0.8-0.1-1.6-0.2-2.3-0.6c-0.5-0.3-0.9-0.8-0.9-1.4l0-0.2l0-0.5c0.2-1,0.5-1.9,0.7-2.9
		c0.1-0.3,0.1-0.5,0.2-0.8C20.1,9.3,21,6,21,6s5.8,0,5.9,0c-0.4,1.8-0.9,3.5-1.4,5.3c-0.1,0.2-0.1,0.5-0.2,0.7
		c-0.1,0.6-0.4,1.1-0.3,1.7c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.5,0.2c0.4,0.1,0.7,0,1.1-0.1c0.7-0.2,1.2-0.7,1.4-1.4
		c0.1-0.4,0.2-0.9,0.4-1.3c0.3-1.1,0.5-2.1,0.8-3.2c0.2-0.7,0.4-1.5,0.6-2.2L35.9,6L35.9,6z"/>
                <polygon className="svg-fill" points="26.3,4.7 26.9,2.5 26.1,2.5 26.2,1.8 29.2,1.8 28.9,2.5 28.1,2.5 27.5,4.7 26.3,4.7 	"/>
                <polygon className="svg-fill" points="28.8,4.7 29.6,1.8 30.8,1.8 30.5,2.9 31.5,2.9 31.8,1.8 33,1.8 32.2,4.7 31,4.7 31.3,3.6 30.3,3.6 
		30,4.7 28.8,4.7 	"/>
                <polygon className="svg-fill" points="32.9,4.7 33.7,1.8 36.7,1.8 36.6,2.5 34.7,2.5 34.6,2.9 36.1,2.9 35.9,3.5 34.4,3.5 34.3,4 36.1,4 
		36,4.7 32.9,4.7 	"/>
            </g>
        </svg>
    );
}

export const Telegraph = ({className}) => {
    return (
        <svg
            x="0px"
            y="0px"
            viewBox="0 0 98.1 23.1"
            preserveAspectRatio="xMidYMid meet"
            className={`brand-logo ${className}`}
        >
            <g>
                <path className="svg-fill" d="M89.1,21.1c2.6-0.2,5.1-1.1,5.1-3.8V8.8L93,7.6l-1.3,1.3v6.4l1.1,1l-2.4,2.2h-0.2l-2.4-2l0.6-0.6V2.7c0-1-0.8-1.5-1.4-1.6
		V0.8c0.2-0.1,0.6-0.2,1.1-0.2c0.8,0,1.6,0.5,1.8,1H90c0.5-0.7,1.3-1,2-1.1c0.2,0,0.5,0,0.8,0.1V1c-0.4,0.1-1,0.6-1,1.9v5.4l3.1-3
		h0.2C95.3,5.6,96.7,6.8,97,7c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.4-0.1,0.7-0.2v0.4c-0.2,0.2-0.6,0.6-0.6,1.2v7.3
		c0,3.3-3.6,5.3-8.4,5.6 M87.9,1L87.9,1c0.4,0.3,0.9,0.7,0.9,1.5v13.3l-0.5,0.5l2,1.7l0.6-0.5l-1.6-1.3l0.3-0.3V2.7
		C89.5,1.6,88.6,1.1,87.9,1 M93.4,20.2c1-0.6,1.9-1.4,1.9-2.8V8.3l-1.5-1.5l-0.6,0.6l1.3,1.3v8.7c0,1.2-0.4,2.1-1.2,2.7L93.4,20.2z"
                />
                <path className="svg-fill" d="M17,8.3c1.4-0.7,3.5-1.9,4.9-3h0.2c0.9,1,2.3,2.5,3.4,3.5v0.3l-5.2,3.4v2.1c0.5,0.3,2.1,1.1,3,1.4l2.1-2l0.2,0.3l-4.3,4.1
		h-0.2c-1.4-0.6-3.3-1.4-4.5-2.1c0.1-0.4,0.3-1.5,0.3-2.9 M21.9,17.6c-1.4-0.6-2.7-1.2-3.8-1.8c0.1-0.4,0.1-0.9,0.1-2.3V8.1
		l-0.8,0.4v5c0,1.4,0,2.3-0.1,2.7c1.1,0.6,2.7,1.3,4.1,1.9L21.9,17.6z M20.4,12.1l2.4-1.6c-0.8-0.9-1.7-1.9-2.4-2.7V12.1z M21.1,6.9
		l-0.6,0.4c0.7,0.9,1.7,2,2.6,3l0.7-0.4C22.8,8.8,21.9,7.7,21.1,6.9"/>
                <path className="svg-fill" d="M30.3,2.4V15c0,0.5,0.5,1.1,1,1.2l0.8-0.8l0.2,0.3l-2.7,2.8h-0.1c-1-0.2-2.5-1.1-2.5-2.7V3.9l-0.8-0.8l-0.8,0.8l-0.2-0.2
		l3-3.1h0.2 M30,17.5c-1-0.3-1.9-0.9-1.9-2.2V3.4l-1.1-1.1l-0.6,0.6l0.9,0.9v11.9c0,1.4,1.1,2.2,2.1,2.5L30,17.5z"/>
                <path className="svg-fill" d="M33.5,8.3c1.4-0.7,3.5-1.9,4.9-3h0.2c0.9,1,2.3,2.5,3.4,3.5v0.3l-5.2,3.4v2.1c0.5,0.3,2.1,1.1,3,1.4l2.1-2l0.2,0.3
		l-4.3,4.1h-0.2c-1.4-0.6-3.3-1.4-4.5-2.1c0.1-0.4,0.3-1.5,0.3-2.9 M38.4,17.6c-1.4-0.6-2.7-1.2-3.8-1.8c0.1-0.4,0.1-0.9,0.1-2.3
		V8.1l-0.8,0.4v5c0,1.4,0,2.3-0.1,2.7c1.1,0.6,2.7,1.3,4.1,1.9L38.4,17.6z M36.9,12.1l2.4-1.6c-0.8-0.9-1.7-1.9-2.4-2.7V12.1z
		 M37.6,6.9L37,7.3c0.7,0.9,1.7,2,2.6,3l0.7-0.4C39.3,8.8,38.4,7.7,37.6,6.9"/>
                <path className="svg-fill" d="M43.4,16.4V8.3l4.3-3h0.2c0.8,0.3,3.6,1.5,3.9,1.7c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.5-0.1,0.8-0.2v0.4
		c-0.2,0.2-0.6,0.7-0.6,1.1v6.8c0,1.6,0.3,2.5,1,3.2v0.3c-0.9,1-3.1,3.2-4.1,4.4h-0.2c-0.9-0.5-2.5-0.9-3.8-0.9
		c-0.9,0-2,0.2-2.8,0.9l-0.2-0.2c0.7-1.7,2.3-3.4,4.3-3.4c1.3,0,2.9,0.5,4.3,1.3c-1-1.1-1.8-3.1-1.8-4.8l-2.5,2.4h-0.2 M45.4,21.1
		c-0.9,0-1.8,0.4-2.3,1.1v0.1c0.6-0.3,1.2-0.5,2.3-0.5c1.3,0,2.9,0.3,3.8,0.9l0.5-0.6C48.6,21.5,47.1,21.1,45.4,21.1 M46.5,18
		l0.6-0.5l-2.6-1.6V8l-0.7,0.5v7.7L46.5,18z M50.3,8.1l-3.6-1.5v0.8l2.8,1.2v7.3c0,1.6,0.7,3.3,1.7,4.6l0.6-0.6
		c-0.9-1.2-1.5-2.6-1.5-4.1V8.1z M49.1,15.4V8.8l-2.4-1.1v7.6l1.5,1L49.1,15.4z"/>
                <path className="svg-fill" d="M59.1,8.8V15c0,0.5,0.5,1,1,1.1l0.8-0.7l0.2,0.3l-2.7,2.8h-0.2c-1-0.2-2.5-1.1-2.5-2.7V8.6L55,7.8l-0.8,0.8l-0.3-0.2l3-3.1
		h0.2l1.9,1.9v1l2.4-2.9h0.2c0.5,0.5,1.3,1.2,1.9,1.7v0l-2,2.4h-0.1c-0.6-0.5-1.3-1.2-1.7-1.6 M58.8,17.5c-1-0.3-1.9-0.9-1.9-2.2
		V8.1l-1-1.1l-0.6,0.6l0.9,0.9v7.2c0,1.4,1.1,2.2,2.1,2.5L58.8,17.5z M60.1,7.7c0.4,0.4,1,1,1.5,1.4l0.5-0.6c-0.5-0.4-1.2-1-1.5-1.4
		L60.1,7.7z"/>
                <path className="svg-fill" d="M66.9,18.4l-2.9-1.9v-3.8c0.7-0.5,2.3-1.6,3-2.1c-1.2-0.6-2.1-1.4-2.7-2.1V8.2c1.7-1,3-1.9,4-2.9h0.2c0,0,3.6,1.7,3.8,1.8
		c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0,0.5-0.1,0.7-0.2v0.4c-0.3,0.2-0.6,0.6-0.6,1.1V15c0,0.7,0.6,1.1,1,1.2l0.8-0.8l0.2,0.3l-2.7,2.8
		h-0.2c-1.1-0.3-2.2-1.3-2.4-2.5L67,18.4 M67.5,17.5l-2.4-1.6v-3.6l-0.7,0.5v3.5l2.6,1.7L67.5,17.5z M69.6,11.3v-2l-3.4-1.8
		c-0.2,0.2-1.2,0.8-1.5,0.9C65.5,9.4,67.6,10.8,69.6,11.3 M72.6,17.5c-0.9-0.3-1.9-1-1.9-2.3V8.6l-3.6-1.8c-0.1,0.1-0.5,0.4-0.6,0.5
		L70,9.1v6.5c0,1.3,1.2,2.2,2.1,2.6L72.6,17.5z M69.6,15.5v-3.9c-0.8-0.2-1.5-0.5-2.3-0.9v4.8l1.4,0.8L69.6,15.5z"/>
                <path className="svg-fill" d="M80,8.3l3.1-3h0.2C83.6,5.6,85,6.9,85.3,7c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5-0.1,0.7-0.2v0.4c-0.2,0.2-0.6,0.7-0.6,1.2
		v7.7c-1.4,0.3-2.4,1.1-3.6,2.4h-0.1L80,18.2v1.7l1.1,1l-2.6,2.1h-0.1L76,21l0.6-0.7v-2.9c-0.2-0.1-0.7-0.1-1-0.2v-0.4
		c0.3-0.1,1-0.9,1-1.5V8.6l-0.8-0.8l-0.8,0.8l-0.3-0.2l3-3.1h0.2L80,7.2 M77,20.5L76.5,21l1.9,1.7l0.6-0.5l-1.5-1.3l0.3-0.3V8.1
		L76.7,7l-0.5,0.5L77,8.5V20.5z M82.4,15.7V8.8l-1.2-1.2L80,8.9v6.4C80.6,15.4,82.1,15.7,82.4,15.7 M82.1,18.3
		c0.1-0.2,0.4-0.5,0.5-0.6L80,17.1v0.7L82.1,18.3z M81.5,7.4l1.3,1.3v7l0.8-0.4v-7l-1.5-1.5L81.5,7.4z"/>
                <path className="svg-fill" d="M1,11.3c0-3.1,2.3-5.2,4.5-6.2l0.1,0.2C4.9,6.2,4.3,7.1,4.3,9c0,2.1,0.6,3.6,1.5,4.6c0.3-0.3,0.5-0.9,0.5-1.3V9.1l3.9-2.8
		l0.2,0.2C10,7,9.8,7.5,9.8,8.2v2c0,1.4-2.3,3-3.8,3.5c1,0.9,2.3,1.3,3.8,1.3c0.5,0,1,0,1.5-0.1V6.1C9.9,6,8.7,5.7,7.6,5.4
		C6.4,5,5.2,4.7,4.2,4.7c-1.6,0-3.5,0.5-3.5,2.8c0,0.2,0,0.4,0.1,0.7L0.4,8.3C0.1,7.6,0,6.9,0,6.2C0,3.4,2,1,5.3,1
		C6.7,1,8,1.4,9.1,1.7c1.2,0.3,2.4,0.7,3.7,0.7c0.6,0,1-0.3,1-0.9c0-0.6-0.3-0.9-0.8-1l0-0.3c1.4,0.2,3,1.1,3,2.9
		c0,2.1-1.5,3.1-4.2,3.1h-0.3V15c1.7-0.3,2.9-1.1,3.7-2.5l0.3,0.1c-1,3-3.2,5.9-7.2,5.9C4.3,18.5,1,16.2,1,11.3 M11.8,5
		C8.9,5,6.3,3.6,4.2,3.6c-1.4,0-2.9,0.6-3.5,2.1l0.1,0c0.6-1.1,2.1-1.4,3.4-1.4c2.1,0,4.7,1.4,7.6,1.4c2.3,0,3.7-0.8,3.9-2.7l-0.1,0
		C15.3,4.5,14.1,5,11.8,5 M3.5,6.8c-1.1,1.1-2.1,2.6-2.1,4.5c0,4.6,3.1,6.8,6.9,6.8c2.4,0,4.1-1.3,5.1-2.3l-0.1-0.1
		c-1.1,0.9-2.7,1.7-5,1.7c-3.1,0-6.1-1.9-6.1-6.1C2.2,9.6,2.8,8,3.5,6.8L3.5,6.8z M7.4,12.1l0-3.3L6.6,9.2v3.1
		c0,0.3-0.1,0.6-0.2,0.9l0.1,0C7,12.9,7.4,12.5,7.4,12.1"/>
            </g>
        </svg>
    );
}

export const Sky = ({className}) => {
    return (
        <svg
            x="0px"
            y="0px"
            viewBox="0 0 38.7 23.2"
            preserveAspectRatio="xMidYMid meet"
            className={`brand-logo ${className}`}
        >

            <g>
                <path className="svg-fill" d="M16.8,0.5c0-0.4-0.2-0.5-0.5-0.5h-1.9c-0.8,0-1.3,0.4-1.3,1.3v16.4c0,0.4,0.2,0.5,0.5,0.5h1.9
		c0.8,0,1.3-0.4,1.3-1.3V0.5L16.8,0.5z"/>
                <path className="svg-fill" d="M38.5,6.5c0.1-0.2,0.2-0.3,0.2-0.5c0-0.3-0.2-0.5-0.5-0.5h-2.3c-0.5,0-0.7,0.2-0.8,0.5l-3.3,7.3l-4.6-7.2
		c-0.3-0.4-0.6-0.6-1.3-0.6h-2.8c-0.6,0-0.9,0.2-1.2,0.5l-4.9,5.2l4.4,6.4c0.3,0.4,0.5,0.5,0.8,0.5h2.6c0.4,0,0.6-0.2,0.6-0.5
		c0-0.2-0.1-0.3-0.2-0.5l-3.8-5.7l2.9-3.1l5.7,8.5l-2.5,5.4c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.3,0.2,0.5,0.5,0.5h2.3
		c0.5,0,0.7-0.2,0.8-0.5L38.5,6.5L38.5,6.5z"/>
                <path className="svg-fill" d="M4.7,10c-0.8-0.2-1-0.4-1-0.8c0-0.3,0.2-0.5,0.9-0.5h5.6c0.9,0,1.3-0.5,1.3-1.3V6.1c0-0.3-0.2-0.5-0.5-0.5H4.4
		C1.5,5.6,0,7.3,0,9.4c0,1.9,1.2,3.1,3.5,3.6l3.7,0.8c0.8,0.2,1,0.4,1,0.8c0,0.3-0.2,0.5-0.9,0.5H1.5c-0.9,0-1.3,0.5-1.3,1.3v1.2
		c0,0.3,0.2,0.5,0.5,0.5h6.4c3.4,0,4.8-1.7,4.8-3.7c0-2.1-1.2-3.2-3.5-3.7L4.7,10L4.7,10z"/>
            </g>
        </svg>
    );
}

export const BulkPowders = ({className}) => {
    return (
        <svg
            x="0px"
            y="0px"
            viewBox="0 0 69.6 23"
            preserveAspectRatio="xMidYMid meet"
            className={`brand-logo ${className}`}
        >
            <g>
                <path className="svg-fill" d="M0.5,18.9c0.3-0.2,0.5-0.5,0.7-0.7c4.6,5,11.4,4.5,15.3,0.6c3.8-3.8,4.1-10,0.6-14.1C13.7,0.6,6.9-0.6,1.8,4.1
		C1.7,3.8,1.5,3.6,1.3,3.3c3.4-3.8,10.4-4.8,15.3-0.6c5.1,4.4,5.5,12.1,0.8,16.8C12.7,24.4,5,24.1,0.5,18.9z"/>
                <path className="svg-fill" d="M3.5,5.6C3.2,5.4,3,5.2,2.8,5.1c1.7-2.2,6.4-4,10.7-1.8c3.9,2,5.9,6.8,4.6,11c-1.4,4.3-5.8,7.1-10.2,6.4
		C3.4,20,0,16,0,11.6c0.3,0,0.6,0,1,0c0.1,2.5,1.1,4.7,3.1,6.3c1.4,1.1,3,1.8,4.8,1.8c3.7,0.1,7-2.2,8.1-5.7
		c1.1-3.4-0.2-7.2-3.2-9.2C11,2.8,6.8,2.6,3.5,5.6z"/>
                <path className="svg-fill" d="M5,7.2C4.7,7,4.5,6.8,4.3,6.6c1.6-1.9,5-2.8,7.9-1.5c2.9,1.4,4.6,4.6,3.9,7.7c-0.7,3.3-3.4,5.7-6.6,5.7
		c-3.2,0.1-6.1-2-7-5.1c0.3-0.1,0.6-0.2,0.9-0.3c0.8,2.1,2.1,3.6,4.3,4.1c1.6,0.4,3.2,0.2,4.6-0.7c2.9-1.8,3.6-5.6,1.7-8.4
		C12.3,5.5,8.3,4.4,5,7.2z"/>
                <path className="svg-fill" d="M37.9,12.5c0.4,1.5,0.7,2.9,1.1,4.4c0,0,0.1,0,0.1,0c0.3-1.2,0.7-2.5,1-3.7c0.1-0.4,0.1-0.7,0.7-0.7c0.6,0,0.6,0.4,0.7,0.8
		c0.3,1.2,0.6,2.4,1.1,3.6c0.3-1.4,0.7-2.8,1-4.3c0.5,0,1,0,1.5,0c-0.6,2.2-1.2,4.3-1.8,6.5c-1.5,0.2-1.4,0.2-1.8-1.1
		c-0.2-1-0.5-2-0.9-3c-0.3,1.4-0.7,2.7-1.1,4.2c-0.4,0-0.7,0-1.1,0c-0.2,0-0.4-0.1-0.4-0.3c-0.6-2.1-1.2-4.1-1.8-6.3
		C36.9,12.5,37.4,12.5,37.9,12.5z"/>
                <path className="svg-fill" d="M23.7,10.7c0-2.2,0-4.3,0-6.5c1.4,0,2.7-0.1,4,0c1.1,0.1,1.6,1.7,0.9,2.6c-0.1,0.2-0.3,0.3-0.4,0.5C29,8,29.3,8.7,29,9.6
		c-0.3,0.8-0.9,1.1-1.7,1.1c-0.4,0-0.7,0-1.1,0C25.4,10.7,24.6,10.7,23.7,10.7z M25.2,9.4c0.6,0,1.2,0.1,1.7,0
		c0.3-0.1,0.6-0.4,0.9-0.6c-0.3-0.3-0.5-0.7-0.8-0.7c-0.6-0.1-1.2,0-1.8,0C25.2,8.5,25.2,8.9,25.2,9.4z M25.2,6.7
		c0.6,0,1.2,0.1,1.7,0c0.3,0,0.5-0.4,0.7-0.6c-0.2-0.2-0.4-0.6-0.7-0.7c-0.5-0.1-1.1,0-1.7,0C25.2,5.8,25.2,6.2,25.2,6.7z"/>
                <path className="svg-fill" d="M45.9,12.5c1,0,1.9,0,2.8,0c2,0.1,3.1,1.4,3.1,3.3c0,1.8-1.2,3.1-3.1,3.2c-0.9,0.1-1.8,0-2.8,0
		C45.9,16.9,45.9,14.7,45.9,12.5z M47.3,17.7c1.1,0.1,2.1,0.2,2.8-0.8c0.5-0.7,0.5-1.8,0-2.4c-0.7-1-1.8-0.8-2.8-0.7
		C47.3,15.1,47.3,16.4,47.3,17.7z"/>
                <path className="svg-fill" d="M36.2,15.8c0,1.9-1.5,3.3-3.4,3.3c-1.9,0-3.4-1.5-3.4-3.4c0-2,1.5-3.4,3.4-3.4C34.9,12.4,36.2,13.8,36.2,15.8z M34.8,15.7
		c0-1.2-0.8-2.1-1.9-2.1c-1.1,0-2,0.9-2,2.2c0,1.2,0.8,2.1,1.9,2.1C34,17.9,34.8,17,34.8,15.7z"/>
                <path className="svg-fill" d="M58.6,12.5c1.1,0,2.1,0,3.2,0c1.1,0.1,1.7,0.6,1.9,1.5c0.2,0.9-0.2,1.8-1.1,2.4c-0.1,0-0.1,0.1-0.2,0.2
		c0.4,0.8,0.9,1.5,1.3,2.3c-1.2,0.3-1.4,0.3-2-0.8c-0.2-0.4-0.3-0.8-0.6-1c-0.2-0.2-0.6-0.3-0.9-0.3c-0.1,0-0.3,0.4-0.3,0.6
		c0,0.5,0,1.1,0,1.6c-0.5,0-0.9,0-1.3,0C58.6,16.9,58.6,14.7,58.6,12.5z M59.9,15.5c0.5,0,1.1,0.1,1.5,0c0.3-0.1,0.7-0.5,0.7-0.8
		c0-0.3-0.4-0.7-0.7-0.8c-0.5-0.1-1,0-1.5,0C59.9,14.3,59.9,14.9,59.9,15.5z"/>
                <path className="svg-fill" d="M57.4,12.5c0,0.4,0,0.7,0,1.2c-1,0-2.1,0-3.1,0c0,0.5,0,0.9,0,1.3c1,0,2,0,3,0c0,0.4,0,0.8,0,1.2c-1,0-2,0-3,0
		c0,0.5,0,0.9,0,1.4c1,0,2,0,3.1,0c0,0.5,0,0.8,0,1.3c-0.7,0-1.5,0-2.2,0c-0.7,0-1.5,0-2.3,0c0-2.2,0-4.3,0-6.5
		C54.3,12.5,55.8,12.5,57.4,12.5z"/>
                <path className="svg-fill" d="M30,4.1c0.5,0,0.9,0,1.4,0c0,0.7,0,1.4,0,2.1c0,0.6,0,1.2,0,1.9c0,0.9,0.5,1.4,1.5,1.5c1,0,1.5-0.5,1.6-1.4
		c0-1,0-1.9,0-2.9c0-0.3,0-0.6,0-1c0.5,0,0.9,0,1.5,0C36,5.8,36,7.4,35.8,9c-0.1,1-1,1.5-2,1.7c-2.3,0.4-3.7-0.6-3.7-3
		C30,6.6,30,5.4,30,4.1z"/>
                <path className="svg-fill" d="M64.3,18.1c0.2-0.3,0.4-0.6,0.6-0.8c0.8,0.2,1.5,0.6,2.3,0.7c0.3,0,0.7-0.4,1.1-0.6c-0.3-0.2-0.5-0.6-0.9-0.7
		c-0.5-0.2-1.1-0.3-1.6-0.5c-0.8-0.3-1.3-0.8-1.2-1.7c0-0.8,0.5-1.5,1.3-1.8c1.3-0.4,2.5-0.2,3.7,0.7c-0.3,0.4-0.5,0.7-0.6,0.9
		c-0.8-0.2-1.5-0.4-2.2-0.5c-0.2,0-0.5,0.3-0.8,0.5c0.2,0.2,0.4,0.5,0.6,0.6c0.6,0.3,1.3,0.4,2,0.6c0.7,0.3,1.1,0.8,1.1,1.6
		c0,0.8-0.3,1.4-1,1.8C67.4,19.5,65.3,19.2,64.3,18.1z"/>
                <path className="svg-fill" d="M42.1,4.1c0.4,0,0.8,0,1.3,0c0,0.9,0,1.7,0,2.5c0.1,0,0.1,0,0.2,0.1c0.5-0.6,1.1-1.2,1.5-1.9C45.6,4,46.3,4,47.3,4.2
		c-0.7,0.9-1.4,1.7-2.1,2.5c-0.3,0.4-0.4,0.6,0,1c0.8,0.9,1.5,1.9,2.3,2.9c-1,0.2-1.8,0.1-2.4-0.9c-0.3-0.6-0.8-1-1.2-1.6
		c-1,0.7-0.3,1.7-0.6,2.5c-0.4,0-0.8,0-1.3,0C42.1,8.5,42.1,6.4,42.1,4.1z"/>
                <path className="svg-fill" d="M23.7,12.6c1.2,0,2.4-0.1,3.5,0c1.1,0.1,1.7,1,1.7,2.2c0,1-0.8,1.8-1.8,1.9c-0.6,0-1.2,0-1.9,0c0,0.8,0,1.6,0,2.3
		c-0.5,0-0.9,0-1.4,0C23.7,16.9,23.7,14.7,23.7,12.6z M25.2,15.4c0.6,0,1.1,0,1.6,0c0.5-0.1,0.8-0.4,0.7-0.9
		c-0.1-0.3-0.4-0.7-0.7-0.8c-0.5-0.1-1,0-1.5,0C25.2,14.3,25.2,14.8,25.2,15.4z"/>
                <path className="svg-fill" d="M37.2,4.2c0.4,0,0.9,0,1.3,0c0,1.7,0,3.4,0,5.2c0.9,0,1.8,0,2.7,0c0,0.5,0,0.8,0,1.2c-1.4,0-2.7,0-4.1,0
		C37.2,8.5,37.2,6.3,37.2,4.2z"/>
            </g>
        </svg>
    );
}

export const TypeCreative = ({className}) => {
    return (
        <svg
            x="0px"
            y="0px"
            viewBox="0 0 113.4 19.2"
            preserveAspectRatio="xMidYMid meet"
            className={`brand-logo ${className}`}
        >
            <g>
                <g>
                    <path className="svg-fill" d="M33.2,4.4c2.1,0,3.2,1.1,3.2,2.3c0,1.7-1.9,2.7-6.1,2.7c-0.2,0.5-0.4,1.7-0.4,2.5c0,1.5,0.7,2.1,1.7,2.1
			c1.2,0,2.5-0.8,3.4-2c0.1-0.1,0.3,0.1,0.3,0.2c-0.9,1.4-2.6,2.4-4.4,2.4c-2.4,0-4.4-1.9-4.4-4.3C26.6,7,29.3,4.4,33.2,4.4z
			 M32.2,5.3c-0.8,0.8-1.4,2.3-1.8,3.7c1.2,0,1.9-0.3,2.4-0.9c0.7-0.7,1-1.5,1-2.5c0-0.7-0.2-1-0.6-1C32.9,4.8,32.7,4.9,32.2,5.3z"
                    />
                    <path className="svg-fill" d="M44.6,4.4c1.8,0,3,1.1,3,2.3c0,1.1-0.9,2-2,2c-0.7,0-1.2-0.5-1.2-1.1c0-0.9,1.1-1.4,1.1-2.1c0-0.4-0.3-0.7-0.8-0.7
			c-0.4,0-0.7,0.2-1.2,0.6c-1.1,1.1-2.3,4.2-2.3,7c0,1.1,0.5,1.8,1.5,1.8c1.2,0,2.6-0.8,3.5-2.1c0.1-0.1,0.4,0,0.3,0.2
			c-0.9,1.2-2.4,2.4-4.4,2.4c-2.4,0-4.2-1.9-4.2-4.3C38,7,40.7,4.4,44.6,4.4z"/>
                    <path className="svg-fill" d="M63.9,4.4c2.1,0,3.2,1.1,3.2,2.3c0,1.7-1.9,2.7-6.1,2.7c-0.2,0.5-0.4,1.7-0.4,2.5c0,1.5,0.7,2.1,1.7,2.1
			c1.2,0,2.5-0.8,3.4-2c0.1-0.1,0.3,0.1,0.3,0.2c-0.9,1.4-2.6,2.4-4.4,2.4c-2.4,0-4.4-1.9-4.4-4.3C57.2,7,59.9,4.4,63.9,4.4z
			 M62.9,5.3C62,6.2,61.4,7.6,61,9.1c1.2,0,1.9-0.3,2.4-0.9c0.7-0.7,1-1.5,1-2.5c0-0.7-0.2-1-0.6-1C63.6,4.8,63.3,4.9,62.9,5.3z"/>
                    <path className="svg-fill" d="M72.1,4.4c1.7,0,2.5,0.8,2.7,2.1l0.1,0L75.4,5c0.1-0.3,0.2-0.4,0.5-0.4h2.6c0.3,0,0.4,0,0.3,0.4l-1.6,5.2
			c-0.5,1.5-0.7,2.2-0.7,2.9c0,0.5,0.2,0.9,0.6,0.9c0.5,0,0.9-0.4,1.6-1.5c0.1-0.1,0.3,0,0.3,0.1c-0.7,1.4-1.6,2.1-3.1,2.1
			c-1.5,0-2.1-1-2.1-2.4c0-0.2,0-0.5,0.1-0.9l-0.1,0c-0.9,2.4-2.1,3.3-3.5,3.3c-1.8,0-3.3-1.9-3.3-3.9C67.1,7.2,69.5,4.4,72.1,4.4z
			 M71.5,13.9c0.7,0,1.7-1,2.6-4l0-0.2c0.9-3.3,0.2-4.4-0.5-4.4c-0.4,0-0.7,0.2-1.2,0.6c-1.1,1.1-2,3.3-2,5.9
			C70.4,13.4,70.9,13.9,71.5,13.9z"/>
                    <path className="svg-fill" d="M95.5,5.9c0-0.4-0.2-0.6-0.4-0.6c-0.3,0-0.7,0.2-1.5,1.4c-0.1,0.1-0.3-0.1-0.3-0.1c0.9-1.7,2-2.2,3.1-2.2
			c1.4,0,2.3,1,2.3,2.3c0,2.1-2.1,4.9-2.1,6.3c0,0.7,0.4,0.9,0.8,0.9c1.8,0,3.9-2.3,3.9-4.4c0-1.5-0.7-1.9-0.7-3.2
			c0-1.1,0.6-1.8,1.4-1.8c0.7,0,1.3,0.7,1.3,1.8c0,3-3.1,8.4-7.2,8.4c-1.8,0-2.9-1-2.9-2.4C93.3,10.4,95.5,7.3,95.5,5.9z"/>
                    <path className="svg-fill" d="M110.2,4.4c2.1,0,3.2,1.1,3.2,2.3c0,1.7-1.9,2.7-6.1,2.7c-0.2,0.5-0.4,1.7-0.4,2.5c0,1.5,0.7,2.1,1.7,2.1
			c1.2,0,2.5-0.8,3.4-2c0.1-0.1,0.3,0.1,0.3,0.2c-0.9,1.4-2.6,2.4-4.4,2.4c-2.4,0-4.4-1.9-4.4-4.3C103.5,7,106.2,4.4,110.2,4.4z
			 M109.2,5.3c-0.8,0.8-1.4,2.3-1.8,3.7c1.2,0,1.9-0.3,2.4-0.9c0.7-0.7,1-1.5,1-2.5c0-0.7-0.2-1-0.6-1
			C109.9,4.8,109.6,4.9,109.2,5.3z"/>
                    <path className="svg-fill" d="M56,4.4c-1.3,0-2.8,1-3.8,4.2l-0.1,0c0.2-0.9,0.2-1.2,0.2-1.6c0-1.3-0.8-2.5-2.3-2.5c-0.6,0-1.2,0.1-1.6,0.4
			c-0.1,0-0.1,0.1-0.1,0.2l0,0c0,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2,0c0.4,0,0.6,0.5,0.6,1c0,0.6-0.2,1.5-0.6,2.8L47.3,14
			c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.2,0.2,0.2h2.8c0.3,0,0.4-0.1,0.5-0.4L52,9.9c1.3-4,2.3-4.8,3.2-4.7c0.2,0,0.3,0.2,0.3,0.3
			c0,0.6-0.7,0.7-0.7,1.6c0,0.5,0.4,0.9,1,0.9c1,0,2-0.8,2-2C57.9,5.1,57.1,4.4,56,4.4z"/>
                    <path className="svg-fill" d="M92.3,12.3c-0.5,0.8-1,1.3-1.5,1.3c-0.4,0-0.7-0.3-0.7-0.7c0-1.4,1.9-4.3,1.9-6.2c0-1.3-0.9-2.2-2.4-2.2
			c-0.7,0-4.3,0-4.3,0l1.1-3.6H86c-0.5,1.2-1.4,2.1-3.7,2.1l-0.5,1.5h-1.5c-0.1,0-0.1,0-0.1,0.2s0,0.2,0.1,0.2h1.4l-2,6.3
			c-0.8,2.4,0.6,3.5,2.2,3.5c1.5,0,2.7-0.9,3.5-2.2c0-0.1-0.2-0.2-0.3-0.1c-0.7,0.9-1.3,1.4-1.9,1.4c-0.4,0-0.7-0.4-0.4-1.3l2.4-7.6
			h2.5c0.9,0,1,0.8,1,1.2c0,1.6-1.9,4.3-1.9,6.2c0,1.3,0.8,2.3,2.4,2.3c1.4,0,2.8-0.7,3.5-2.1C92.6,12.3,92.4,12.2,92.3,12.3z"/>
                    <path className="svg-fill" d="M91.8,0c-1.3,0-2.3,1.1-2.3,2.2c0,0.9,0.6,1.4,1.5,1.4c1.3,0,2.3-1.1,2.3-2.2C93.3,0.6,92.7,0,91.8,0z"/>
                    <path className="svg-fill" d="M23,4.4c-1.8,0-2.9,1.2-3.7,3.3l-0.1,0c0.1-0.3,0.1-0.7,0.1-0.9c0-1.3-0.8-2.4-2.3-2.4c-1.1,0-1.9,0.4-2.7,1.5
			c-1.3,1.9-2.1,3.2-2.6,4.3h-0.1c-0.1-3.6-0.8-5.8-3.1-5.8c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0H5.8L7,0.8H6.6C6,2,5.1,2.9,2.8,2.9
			L2.3,4.4H0.8c-0.1,0-0.1,0-0.1,0.2c0,0.2,0,0.2,0.1,0.2h1.4l-1.9,6.4c-0.8,2.4,0.6,3.5,2.2,3.5c1.5,0,2.7-0.9,3.5-2.2
			c0-0.1-0.2-0.2-0.3-0.1c-0.7,0.9-1.3,1.4-1.9,1.4c-0.4,0-0.7-0.4-0.4-1.3l2.3-7.6h0.5c0.4,0,0.6,0.1,0.7,0.2c0.9,0.9,2,5.6,2,9.5
			c0,1.6-1.1,2.9-1.8,3.5c0.2-0.3,0.3-0.7,0.3-1.1c0-0.9-0.7-1.6-1.7-1.6c-1.5,0-2.6,1.2-2.6,2.5c0,0.7,0.3,1.2,0.9,1.4l0,0
			c0,0,0,0,0,0c0.2,0.1,0.5,0.2,0.9,0.2c1,0,2.1-0.6,2.9-1.5c0.5-0.6,1.2-1.6,1.2-1.6c0.5-0.8,1-1.8,1.5-2.9
			c1.4-2.8,1.8-3.5,2.7-4.8c0,0,2.3-3.4,3.1-3.1c0.8,0.4-1.4,7.6-2.7,11.7c-0.3,1.1,0.4,2.1,1.6,2.1c0.7,0,1.4-0.5,1.6-1.2l1-3.3
			c0.2-0.8,0.4-1.6,0.6-2.4l0.1,0c0.2,1.3,1.2,2.1,2.9,2.1c2.6,0,5.1-2.8,5.1-6.4C26.3,5.9,24.7,4.4,23,4.4z M20.9,13.2
			c-0.4,0.4-0.8,0.6-1.2,0.6c-0.7,0-1.4-0.9-0.9-3.5c0.1-0.3,0.1-0.6,0.2-0.9l0-0.2c0.9-3,1.9-4,2.8-4c0.5,0,1.1,0.5,1.1,2.1
			C22.9,9.9,22,12.1,20.9,13.2z"/>
                </g>
            </g>
        </svg>
    );
}

export const Breakfree = ({className}) => {
    return (
        <svg
            x="0px"
            y="0px"
            viewBox="0 0 86.4 16.6"
            preserveAspectRatio="xMidYMid meet"
            className={`brand-logo ${className}`}
        >
            <g>
                <g>
                    <path className="svg-fill" d="M20.8,0.7c0-0.3,0.2-0.5,0.5-0.5h0.5c0.3,0,0.5,0.2,0.5,0.5v3.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2h0
					c0.1-0.3,0.3-0.5,0.6-0.7c0.2-0.2,0.5-0.3,0.8-0.5c0.3-0.2,0.7-0.2,1.2-0.2c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.8,0.5,1.1,0.8
					C27.6,4.6,27.8,5,28,5.5s0.2,1,0.2,1.6c0,0.6-0.1,1.2-0.3,1.7c-0.2,0.5-0.4,0.9-0.7,1.3c-0.3,0.4-0.7,0.6-1.1,0.8
					c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.8-0.1-1.2-0.2c-0.3-0.2-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.5-0.7h-0.1c0,0,0,0.1,0,0.1
					c0,0.1,0,0.2,0,0.3v0.4c0,0.3-0.2,0.4-0.5,0.4h-0.4c-0.3,0-0.5-0.2-0.5-0.5V0.7z M22.2,7.2c0,0.4,0.1,0.7,0.1,1
					c0.1,0.3,0.2,0.6,0.4,0.9c0.2,0.3,0.4,0.5,0.7,0.6c0.3,0.2,0.6,0.2,1,0.2c0.3,0,0.6-0.1,0.9-0.2s0.5-0.3,0.7-0.5
					c0.2-0.2,0.4-0.5,0.5-0.9c0.1-0.3,0.2-0.7,0.2-1.2c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.3-0.3-0.6-0.4-0.9c-0.2-0.2-0.4-0.4-0.7-0.6
					s-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.4,0.5-0.5,0.9C22.3,6.3,22.2,6.7,22.2,7.2z"/>
                    <path className="svg-fill" d="M29.9,3.8c0-0.3,0.2-0.5,0.5-0.5h0.4c0.3,0,0.5,0.2,0.5,0.5v0.9c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2h0
					c0.1-0.3,0.2-0.5,0.3-0.8C31.8,4.2,32,4,32.2,3.8c0.2-0.2,0.4-0.3,0.7-0.5s0.5-0.2,0.8-0.2c0.3,0,0.5,0.2,0.5,0.5v0.4
					c0,0.3-0.2,0.5-0.5,0.5c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.7c-0.2,0.3-0.3,0.6-0.4,1c-0.1,0.4-0.1,0.8-0.1,1.1v2.7
					c0,0.3-0.2,0.5-0.5,0.5h-0.5c-0.3,0-0.5-0.2-0.5-0.5L29.9,3.8L29.9,3.8z"/>
                    <path className="svg-fill" d="M34.6,7.2c0-0.6,0.1-1.2,0.3-1.7c0.2-0.5,0.5-0.9,0.8-1.3c0.3-0.4,0.8-0.6,1.2-0.8c0.5-0.2,1-0.3,1.5-0.3
					c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.8,0.4,1,0.8s0.5,0.7,0.7,1.2c0.2,0.4,0.2,0.9,0.2,1.5c0,0.1-0.1,0.3-0.2,0.4
					c-0.1,0.1-0.2,0.2-0.4,0.2h-5.2c0,0.4,0.1,0.8,0.3,1.1s0.4,0.6,0.6,0.8c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.7,0.2,1,0.2
					c0.4,0,0.8-0.1,1.1-0.2c0.3-0.1,0.6-0.3,0.7-0.4c0.3-0.2,0.5-0.1,0.7,0.2l0.1,0.2c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1-0.1,0.2-0.2,0.3
					c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.3,0.1-0.6,0.1-0.9,0.1c-0.6,0-1.2-0.1-1.7-0.3
					c-0.5-0.2-0.9-0.5-1.3-0.9c-0.4-0.4-0.7-0.8-0.8-1.3C34.7,8.3,34.6,7.7,34.6,7.2z M40.3,6.3c0-0.7-0.2-1.2-0.6-1.6
					c-0.4-0.3-0.8-0.5-1.3-0.5c-0.6,0-1.1,0.2-1.5,0.5c-0.4,0.4-0.7,0.9-0.8,1.5C36.1,6.3,40.3,6.3,40.3,6.3z"/>
                    <path className="svg-fill" d="M42.8,8.9c0-0.4,0.1-0.7,0.2-1c0.2-0.3,0.4-0.5,0.6-0.7c0.3-0.2,0.5-0.4,0.9-0.5c0.3-0.1,0.7-0.2,1-0.3
					c0.3-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0h0.4V6c0-0.3-0.1-0.6-0.1-0.8c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.3-0.2-0.5-0.3
					c-0.2-0.1-0.4-0.1-0.7-0.1c-0.4,0-0.7,0.1-1,0.2c-0.3,0.1-0.5,0.2-0.7,0.3c-0.3,0.1-0.5,0.1-0.7-0.2l-0.1-0.2
					c-0.2-0.3-0.1-0.5,0.2-0.7c0.2-0.1,0.6-0.3,1-0.4s0.9-0.2,1.5-0.2c1,0,1.7,0.3,2.3,0.8c0.5,0.5,0.8,1.3,0.8,2.3v4.4
					c0,0.3-0.2,0.5-0.5,0.5h-0.4c-0.3,0-0.5-0.2-0.5-0.5v-0.4c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2c0,0,0-0.1,0-0.1h0
					c-0.2,0.3-0.3,0.6-0.6,0.8c-0.2,0.2-0.5,0.4-0.8,0.6c-0.3,0.2-0.7,0.3-1.2,0.3c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.3-0.8-0.4
					c-0.2-0.2-0.4-0.4-0.6-0.7C42.9,9.6,42.8,9.3,42.8,8.9z M44.3,8.8c0,0.3,0.1,0.6,0.4,0.9c0.2,0.3,0.6,0.4,1.1,0.4
					c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0.1-0.3,0.2-0.6,0.2-0.9V7.2h-0.4c-0.3,0-0.6,0-1,0
					c-0.4,0-0.7,0.1-1,0.2c-0.3,0.1-0.6,0.3-0.8,0.5C44.4,8.1,44.3,8.4,44.3,8.8z"/>
                    <path className="svg-fill" d="M51.4,0.7c0-0.3,0.2-0.5,0.5-0.5h0.5c0.3,0,0.5,0.2,0.5,0.5v5.4H54l1.9-2.5c0.1-0.2,0.3-0.3,0.6-0.3h0.6
					c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0,0.3-0.1,0.4l-2.2,2.8v0l2.5,3.7c0.1,0.2,0.1,0.3,0.1,0.4C57.7,11,57.5,11,57.3,11h-0.6
					c-0.3,0-0.5-0.1-0.6-0.4L54,7.4h-1.1v3.1c0,0.3-0.2,0.5-0.5,0.5h-0.5c-0.3,0-0.5-0.2-0.5-0.5L51.4,0.7L51.4,0.7z"/>
                    <path className="svg-fill" d="M59.4,5.3h-0.2c-0.6,0-0.8-0.3-0.8-0.8V4.1c0-0.6,0.3-0.8,0.8-0.8h0.2V2.9c0-0.6,0.1-1.1,0.4-1.5c0.3-0.4,0.6-0.7,0.9-0.9
					c0.4-0.2,0.7-0.3,1.1-0.4C62.3,0,62.6,0,62.8,0H63c0.6,0,0.8,0.3,0.8,0.8v0.6c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.4,0.2-0.6,0.2
					c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.4v0.2h0.6c0.6,0,0.8,0.3,0.8,0.8
					v0.4c0,0.6-0.3,0.8-0.8,0.8h-0.6v4.8c0,0.6-0.3,0.8-0.8,0.8h-1c-0.6,0-0.8-0.3-0.8-0.8L59.4,5.3L59.4,5.3z"/>
                    <path className="svg-fill" d="M64.5,4c0-0.6,0.3-0.8,0.8-0.8h0.9C66.7,3.1,67,3.4,67,4v0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2h0
					c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.3,0.6-0.5C68.6,3.1,68.9,3,69.1,3c0.3,0,0.4,0.1,0.6,0.2
					c0.1,0.1,0.2,0.4,0.2,0.6v0.9c0,0.6-0.3,0.8-0.8,0.8c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.5-0.3,0.8
					c-0.1,0.3-0.1,0.6-0.1,0.9v2.2c0,0.6-0.3,0.8-0.8,0.8h-1c-0.6,0-0.8-0.3-0.8-0.8L64.5,4L64.5,4z"/>
                    <path className="svg-fill" d="M70.2,7.1c0-0.6,0.1-1.1,0.3-1.6s0.5-0.9,0.8-1.3c0.4-0.4,0.8-0.7,1.3-0.9c0.5-0.2,1.1-0.3,1.7-0.3c0.6,0,1.1,0.1,1.6,0.3
					C76.3,3.4,76.7,3.7,77,4s0.5,0.7,0.7,1.2s0.2,1,0.2,1.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.3-0.7,0.3h-4.2
					c0.1,0.5,0.3,0.8,0.7,1.1S74.3,9,74.7,9c0.3,0,0.5,0,0.8-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.3-0.1,0.5-0.2,0.7-0.1
					c0.2,0,0.3,0.2,0.5,0.4l0.2,0.4c0.1,0.2,0.2,0.5,0.1,0.7c0,0.2-0.2,0.4-0.4,0.5c-0.3,0.2-0.7,0.3-1.1,0.5c-0.5,0.2-1,0.2-1.6,0.2
					c-0.7,0-1.3-0.1-1.9-0.4c-0.5-0.2-1-0.5-1.4-0.9c-0.4-0.4-0.6-0.8-0.8-1.3C70.3,8.1,70.2,7.6,70.2,7.1z M75.3,6.1
					c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.4,0-0.7,0.1-0.9,0.4C73.2,5.5,73,5.8,73,6.1C73,6.1,75.3,6.1,75.3,6.1z"/>
                    <path className="svg-fill" d="M78.6,7.1c0-0.6,0.1-1.1,0.3-1.6s0.5-0.9,0.8-1.3c0.4-0.4,0.8-0.7,1.3-0.9c0.5-0.2,1.1-0.3,1.7-0.3c0.6,0,1.1,0.1,1.6,0.3
					s0.8,0.5,1.1,0.8s0.5,0.7,0.7,1.2s0.2,1,0.2,1.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.3-0.7,0.3h-4.2c0.1,0.5,0.3,0.8,0.7,1.1
					C82.3,8.9,82.7,9,83.1,9c0.3,0,0.5,0,0.8-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.3-0.1,0.5-0.2,0.7-0.1c0.2,0,0.3,0.2,0.5,0.4L86,9.4
					c0.1,0.2,0.2,0.5,0.1,0.7c0,0.2-0.2,0.4-0.4,0.5c-0.3,0.2-0.7,0.3-1.1,0.5c-0.5,0.2-1,0.2-1.6,0.2c-0.7,0-1.3-0.1-1.9-0.4
					c-0.5-0.2-1-0.5-1.4-0.9c-0.4-0.4-0.6-0.8-0.8-1.3C78.7,8.1,78.6,7.6,78.6,7.1z M83.7,6.1c0-0.3-0.1-0.6-0.3-0.8
					C83.3,5,83,4.9,82.7,4.9c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.5-0.4,0.8C81.4,6.1,83.7,6.1,83.7,6.1z"/>
                </g>
                <g>
                    <path className="svg-fill" d="M17.8,7.5L17.8,7.5C17.8,7.6,17.8,7.5,17.8,7.5z"/>
                    <path className="svg-fill" d="M7.5,11.4C7.5,11.4,7.5,11.4,7.5,11.4C7.5,11.4,7.5,11.4,7.5,11.4z"/>
                    <path className="svg-fill" d="M15,2.2c-15.7-4.8-13.6,14.6-4.2,9.6c3.2-1.2,4.3-6-0.2-7.3c-1.8-0.3-2.3,1-3.2,1.1C7.8,5.1,7.8,5,7.6,5.2
					C7.1,5.7,7.1,6.5,6.8,7.4c0.4,3.2,6.3,2.1,3.6-0.7c2.3,2.8-4.1,3.2-2.7-0.3c1.6-2.3,5.6-0.5,5.2,1.4C12.4,11,7.1,12.9,5.7,10
					C4.8,9,4.5,8.4,4.7,7.1C8-1.6,23.4,5,11.9,13.5C-0.6,19.3-0.2,1.6,6.4,0.6C0.9-1.9-4.2,13.4,5.3,16.1C15.2,19.2,21.8,5.7,15,2.2z"
                    />
                    <path className="svg-fill" d="M13.6,8.1L13.6,8.1C13.6,8.3,13.6,8.2,13.6,8.1z"/>
                </g>
                <g>
                    <path className="svg-fill" d="M20.9,12.4h0.4v1.8h2.3v-1.8h0.4v3.9h-0.4v-1.8h-2.3v1.8h-0.4V12.4z"/>
                    <path className="svg-fill" d="M28.4,12.4c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2C26.5,13.2,27.3,12.4,28.4,12.4z M28.4,16
					c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.7-1.6-1.6-1.6s-1.6,0.7-1.6,1.6C26.8,15.3,27.5,16,28.4,16z"/>
                    <path className="svg-fill" d="M32.9,12.4h0.4V16h1.8v0.3h-2.2V12.4z"/>
                    <path className="svg-fill" d="M37.4,12.4h0.4v3.9h-0.4V12.4z"/>
                    <path className="svg-fill" d="M40.6,12.4h1.3c1.2,0,2,0.7,2,1.9c0,1.2-0.8,2-2,2h-1.3L40.6,12.4L40.6,12.4z M41.8,16c1,0,1.6-0.6,1.6-1.6
					c0-1-0.6-1.6-1.6-1.6H41V16C41,16,41.8,16,41.8,16z"/>
                    <path className="svg-fill" d="M48.2,15.1h-1.6l-0.4,1.2h-0.4l1.4-3.9h0.4l1.4,3.9h-0.4L48.2,15.1z M47.5,12.8c0,0-0.1,0.3-0.2,0.5l-0.5,1.4h1.3
					l-0.5-1.4C47.6,13.2,47.5,12.8,47.5,12.8L47.5,12.8z"/>
                    <path className="svg-fill" d="M52,14.6l-1.3-2.2h0.4l0.8,1.4c0.1,0.2,0.2,0.5,0.2,0.5h0c0,0,0.1-0.3,0.2-0.5l0.8-1.4h0.4l-1.3,2.2v1.7H52L52,14.6
					L52,14.6z"/>
                    <path className="svg-fill" d="M55.9,15.6c0,0,0.4,0.4,1,0.4c0.4,0,0.8-0.3,0.8-0.7c0-1-1.9-0.7-1.9-1.9c0-0.6,0.5-1,1.2-1c0.7,0,1,0.4,1,0.4l-0.2,0.3
					c0,0-0.3-0.3-0.9-0.3c-0.5,0-0.8,0.3-0.8,0.7c0,0.9,1.9,0.7,1.9,1.9c0,0.6-0.4,1-1.2,1c-0.8,0-1.2-0.5-1.2-0.5L55.9,15.6z"/>
                    <path className="svg-fill" d="M60.4,15.9h0.4v0.4h-0.4V15.9z"/>
                    <path className="svg-fill" d="M65.1,12.4c0.9,0,1.4,0.5,1.4,0.5l-0.2,0.3c0,0-0.4-0.4-1.2-0.4c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.7,1.6,1.7
					c0.8,0,1.3-0.5,1.3-0.5l0.2,0.3c0,0-0.5,0.6-1.5,0.6c-1.1,0-2-0.9-2-2C63.1,13.2,64,12.4,65.1,12.4z"/>
                    <path className="svg-fill" d="M70.7,12.4c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2C68.7,13.2,69.6,12.4,70.7,12.4z M70.7,16
					c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C69.1,15.3,69.8,16,70.7,16z"/>
                    <path className="svg-fill" d="M74.9,15.9h0.4v0.4h-0.4V15.9z"/>
                    <path className="svg-fill" d="M77.8,12.4h0.4v2.5c0,0.7,0.4,1.1,1.1,1.1c0.7,0,1.1-0.4,1.1-1.1v-2.5h0.4V15c0,0.9-0.6,1.4-1.5,1.4
					c-0.9,0-1.5-0.6-1.5-1.4L77.8,12.4L77.8,12.4z"/>
                    <path className="svg-fill" d="M83.5,12.4h0.4v1.7h0.7l1-1.7H86l-1.2,1.8v0l1.2,2h-0.4l-1.1-1.8h-0.7v1.8h-0.4L83.5,12.4L83.5,12.4z"/>
                </g>
            </g>
        </svg>
    );
}
